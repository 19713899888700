import { Box, Flex } from '@chakra-ui/react';
import clsx from 'clsx';
import Image from 'next/image';
import { match } from 'ts-pattern';

import { GetStockTickerPrice } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import type { DonationRecipient, StockTicker, UUID } from '@endaoment-frontend/types';
import { AwaitingIcon, CheckmarkIcon, CloseIcon } from '@endaoment-frontend/ui/icons';
import { Button, Pill } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency, roundDown } from '@endaoment-frontend/utils';

import styles from '../DonationWizard.module.scss';

import tgbIcon from './brokerage-icons/tgb.png';
import { BrokerageIcon, type BrokerageInfoFormData } from './BrokerageInfo';
import stockStyles from './StockDonation.module.scss';

export const calculateStockFee = (amount: number): number => {
  const feePercent = defaults.fees.stockDonationFeeBps / 10000;
  return roundDown(feePercent * amount, 2);
};

// const generateCancelStockDonation = (broker: string, accountNumber: string, ticker: string, amount: number) =>
//   `mailto:admin@endaoment.org?subject=Stock%20Donation%20Cancellation%20Request
//       &body=Hello%2C%0D%0A%0D%0AI%20am%20writing%20to%20cancel%20my%20Stock%20Donation%20with%20the%20following%20details%3A%0D%0A%0D%0A
//       Broker%3A%20${broker}%0A
//       Account%20Number%3A%20${accountNumber}%0A
//       Stock%3A%20${ticker}%0A
//       Amount%3A%20${amount}%0D%0A
//       %0D%0APlease%20add%20any%20additional%20details%20below%3A%0D%0A`;

export const ViewBrokerageDonation = ({
  pledgeId,
  recipient,
  brokerageInfo,
  shares,
  ticker,
  status,
  onClose,
}: {
  pledgeId?: UUID;
  recipient: DonationRecipient;
  brokerageInfo?: BrokerageInfoFormData;
  shares: number;
  ticker: StockTicker;
  status: 'error' | 'idle' | 'loading' | 'success';
  onClose: () => void;
}) => {
  const { data: currentTickerPrice } = GetStockTickerPrice.useQuery([ticker?.ticker], {
    enabled: !!ticker,
  });
  const donationValue = currentTickerPrice ? currentTickerPrice * shares : 0;

  return (
    <>
      <EntityCardWithLabel label='Donating to' entity={recipient} />
      <div className={styles['donation-info']}>
        {!!brokerageInfo && (
          <div>
            <h4>Brokerage</h4>
            <h4 className={stockStyles['broker-info']}>
              <BrokerageIcon brokerageName={brokerageInfo.brokerage.name} />
              {brokerageInfo.brokerage.label}
            </h4>
          </div>
        )}
        <div>
          <h4>Ticker</h4>
          <h5>{ticker.ticker}</h5>
        </div>
        <div>
          <h4>Shares</h4>
          <h5>{shares}</h5>
        </div>
        <hr />
        <div>
          <h4>{status !== 'success' && 'Estimated'} Value</h4>
          <h5>{donationValue > 0 ? `${status !== 'success' ? '~' : ''}${formatCurrency(donationValue)}` : ''}</h5>
        </div>
        <div>
          <h6>{status !== 'success' && 'Estimated'} Fee</h6>
          <h6>
            {donationValue > 0
              ? `${status !== 'success' ? '~' : ''}${formatCurrency(calculateStockFee(donationValue), { fraction: 2 })}`
              : 'Unable to Compute'}
          </h6>
        </div>
        <hr />
        {match(status)
          .with('success', () => (
            <div className={styles['info']}>
              <Pill variation='green' size='tiny'>
                <CheckmarkIcon strokeWidth={5} />
                Completed
              </Pill>
            </div>
          ))
          .with('error', () => (
            <div className={styles['info']}>
              <Pill variation='red' size='tiny'>
                <CloseIcon />
                Transfer Failed
              </Pill>
              <Box mt='1rem'>
                Please <a href='mailto:help@endaoment.org'>contact our team</a> for more information.
              </Box>
            </div>
          ))
          .otherwise(() => (
            <>
              <Pill className={clsx(styles.disclaimer, styles.disclaimer__short)} variation='green'>
                <p>
                  No action needed. We are in the process of transferring your donation. This may take 7-10 days. You
                  have been sent an email with further details and{' '}
                  <a href='mailto:help@endaoment.org' className={styles['mailto']}>
                    {' '}
                    our donor support team is always here to assist
                  </a>{' '}
                  with questions.
                </p>
              </Pill>
              <Pill className={clsx(styles.disclaimer, styles.disclaimer__short)} marginTop={3}>
                {`Stock donations processed in partnership with `}
                <Image src={tgbIcon} width={100} height={25} alt='The Giving Block Logo' />
              </Pill>
              <Box className={styles['awaiting']} mt='1rem'>
                <AwaitingIcon />
                Awaiting Donation...
              </Box>
              <hr />
              <div className={styles.info}>
                You can safely close this modal while the operation occurs. Check your email inbox for more details, or{' '}
                <a href='mailto:help@endaoment.org' className={styles['mailto']}>
                  contact our team
                </a>
                .
              </div>
              <Flex flexDirection='row' className={styles['modal-actions']}>
                <Button onClick={onClose} size='medium' float={false}>
                  Close
                </Button>
                <Button
                  as='a'
                  href={`mailto:admin@endaoment.org?subject=Endaoment: Stock Donation Cancel Request - ID ${pledgeId}`}
                  variation='red'
                  size='medium'
                  float={false}>
                  Cancel Donation
                </Button>
              </Flex>
            </>
          ))}
      </div>
    </>
  );
};
